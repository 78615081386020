import map from 'lodash/map'
import { Button, Col, Row, Tooltip } from 'antd'
import { Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm, touch } from 'redux-form'
import { SaveOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'

// utils
import { FORMS, PROMO_CODE_TARGET_DISCOUNT, PROMOCODE_LIFESPAN, PROMOCODE_TYPE } from '../../../utils/enums'
import { formatPromoCodeType, getErrorFieldsLabel, getFieldLabel, getPromoCodeTargetDiscountOptions, getPromoCodesLifespan } from '../../../utils/helpers'

// types
import { IFieldLabels } from '../../../types/interfaces'

// forms
import validatePromoCodeForm, { PromoCodeFormValues } from './validatePromoCodeForm'

// components
import DetailHeader from '../../../components/DetailHeader'

// atoms
import SelectField from '../../../atoms/form/SelectField'
import SliderField from '../../../atoms/form/SliderField'
import SwitchField from '../../../atoms/form/SwitchField'
import TextField from '../../../atoms/form/TextField'
import DateField from '../../../atoms/form/DateField'

type PromoCodeFormProps = {
	isCreate?: boolean
}

const PromoCodeForm = ({
	handleSubmit,
	invalid,
	pristine,
	isCreate = false
}: PromoCodeFormProps & InjectedFormProps<PromoCodeFormValues, PromoCodeFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.PROMOCODE_FORM)(state)) as PromoCodeFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.PROMOCODE_FORM))

	// constants
	const promoCodesTypes = map(PROMOCODE_TYPE, (item) => ({
		value: item,
		label: formatPromoCodeType(item)
	}))

	const promoLifeSpan = map(PROMOCODE_LIFESPAN, (item) => ({
		value: item,
		label: getPromoCodesLifespan(item)
	}))

	const fieldLabels: IFieldLabels = {
		code: t('Kód'),
		type: t('Typ'),
		targetDiscount: t('Typ členstva s nárokom na zľavu'),
		recurringPeriods: t('Dĺžka trvania zľavy'),
		argument: t('Počet mesiacov'),
		lifespan: t('Platnosť'),
		validFrom: t('Platnosť od'),
		validTo: t('Platnosť do')
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať promo kód') : t('Detail promo kódu')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať nový promo kód'),
										path: t('paths:promoCodeCreate|path')
									}
								]
							}
						/>
						<Field name={'code'} component={TextField} label={t('Kód')} size={'large'} required />

						<Field name={'type'} component={SelectField} label={t('Typ')} options={promoCodesTypes} required />

						{fieldValues?.type === PROMOCODE_TYPE.DISCOUNT && (
							<Field
								name={'targetDiscount'}
								component={SelectField}
								label={t('Typ členstva s nárokom na zľavu')}
								options={getPromoCodeTargetDiscountOptions(t)}
								multiple
								required
							/>
						)}

						{fieldValues?.targetDiscount?.includes(PROMO_CODE_TARGET_DISCOUNT.RECURRING) && (
							<Field
								name={'recurringPeriods'}
								component={TextField}
								type={'number'}
								label={t('Dĺžka trvania zľavy')}
								placeholder={t('Zadaj počet mesiacov')}
								min={1}
								suffix='mesiacov'
								required
							/>
						)}

						<Row gutter={24}>
							<Col>
								<Field name={'premiumTierAllowed'} component={SwitchField} label={t('Premium')} />
							</Col>
							<Col>
								<Field name={'vipTierAllowed'} component={SwitchField} label={t('VIP')} />
							</Col>
						</Row>

						{fieldValues?.type === PROMOCODE_TYPE.FREE_MONTHS && (
							<Field name={'argument'} component={TextField} label={t('Počet mesiacov')} type={'number'} min={1} integer required />
						)}

						{fieldValues?.type === PROMOCODE_TYPE.DISCOUNT && (
							<Field name={'argument'} component={SliderField} label={t('Percentuálna zľava')} type={'number'} min={1} max={100} required />
						)}

						<Field
							name={'lifespan'}
							component={SelectField}
							label={t('Platnosť')}
							options={promoLifeSpan}
							defaultpickervalue={PROMOCODE_LIFESPAN.LONG_TERM}
							required
						/>

						{fieldValues?.lifespan === PROMOCODE_LIFESPAN.LONG_TERM && (
							<Field
								name={'maxUsageOfPromoCode'}
								component={TextField}
								label={t('Maximálny Počet použití')}
								type={'number'}
								placeholder={t('neobmedzený')}
								min={1}
							/>
						)}

						<Row gutter={8}>
							<Col span={12}>
								<Field
									name={'validFrom'}
									component={DateField}
									label={t('Platnosť od')}
									dttmFormat={'D.M.YYYY'}
									required
									onChange={() => dispatch(touch(FORMS.PROMOCODE_FORM, 'validTo'))}
								/>
							</Col>
							<Col span={12}>
								<Field name={'validTo'} component={DateField} label={t('Platnosť do')} dttmFormat={'D.M.YYYY'} required />
							</Col>
						</Row>

						<Field name={'sendTransactionalEmail'} component={SwitchField} label={t('Transakčný e-mail')} />
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}

						<Field name={'forNewcomers'} component={SwitchField} label={t('Len pre nových členov')} />
						<Field name={'state'} component={SwitchField} label={t('Stav')} />

						<div className={'flex direction-col justify-center'}>
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<PromoCodeFormValues, PromoCodeFormProps>({
	form: FORMS.PROMOCODE_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validatePromoCodeForm
})(PromoCodeForm)
