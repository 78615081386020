import React, { useCallback, useEffect } from 'react'
import { Col, Row } from 'antd'
import { Field, getFormValues, initialize, InjectedFormProps, reduxForm } from 'redux-form'
import { map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// helpers
import { formatPromoCodeState, formatPromoCodeType, getPromoCodesLifespan, getPromoCodeTargetDiscountOptions } from '../../../utils/helpers'

// enums
import { FORMS, PROMO_CODE_TARGET_DISCOUNT, PROMOCODE_LIFESPAN, PROMOCODE_STATE, PROMOCODE_TYPE } from '../../../utils/enums'

// validators
import validateGeneratePromoCodesForm, { GeneratePromoCodesFormValues } from './validateGeneratePromoCodesForm'

// form fields
import DateField from '../../../atoms/form/DateField'
import SelectField from '../../../atoms/form/SelectField'
import SliderField from '../../../atoms/form/SliderField'
import SwitchField from '../../../atoms/form/SwitchField'
import TextField from '../../../atoms/form/TextField'

type GeneratePromoCodesFormProps = {
	formID: string
}

const GeneratePromoCodesForm = ({
	formID,
	handleSubmit
}: GeneratePromoCodesFormProps & InjectedFormProps<GeneratePromoCodesFormValues, GeneratePromoCodesFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const fieldValues: any = useSelector((state) => getFormValues(FORMS.GENERATE_PROMO_CODES)(state))

	const promoCodesTypes = map(PROMOCODE_TYPE, (item) => ({
		value: item,
		label: formatPromoCodeType(item)
	}))

	const promoCodesStates = map(PROMOCODE_STATE, (item) => ({
		value: item,
		label: formatPromoCodeState(item)
	}))

	const promoLifeSpan = map(PROMOCODE_LIFESPAN, (item) => ({
		value: item,
		label: getPromoCodesLifespan(item)
	}))

	const initForm = useCallback(() => {
		const initValues = {
			type: PROMOCODE_TYPE.DISCOUNT,
			targetDiscount: [],
			premiumTierAllowed: false,
			vipTierAllowed: false,
			argument: 10,
			forNewcomers: false,
			state: PROMOCODE_STATE.ACTIVE,
			lifespan: PROMOCODE_LIFESPAN.LONG_TERM,
			sendTransactionalEmail: false
		}

		dispatch(initialize(FORMS.GENERATE_PROMO_CODES, initValues))
	}, [dispatch])

	useEffect(() => {
		initForm()
	}, [initForm])

	return (
		<form id={formID} onSubmit={handleSubmit}>
			<Row gutter={[8, 8]}>
				<Col span={12}>
					<Field name={'prefix'} label={t('Prefix')} component={TextField} required />
				</Col>
				<Col span={12}>
					<Field name={'codeLength'} label={t('Počet znakov generovanej časti')} type={'number'} min={4} max={10} component={TextField} required />
				</Col>
				<Col span={24}>
					<Row gutter={24}>
						<Col>
							<Field name={'premiumTierAllowed'} component={SwitchField} label={t('Premium')} />
						</Col>
						<Col>
							<Field name={'vipTierAllowed'} component={SwitchField} label={t('VIP')} />
						</Col>
						<Col>
							<Field name={'forNewcomers'} component={SwitchField} label={t('Len pre nových členov')} />
						</Col>
					</Row>
				</Col>

				<Col span={24}>
					<Field name={'type'} component={SelectField} label={t('Typ')} options={promoCodesTypes} required />
				</Col>

				{fieldValues && fieldValues?.type === PROMOCODE_TYPE.FREE_MONTHS && (
					<Col span={24}>
						<Field name={'argument'} label={t('Počet mesiacov')} type={'number'} min={1} component={TextField} integer required />
					</Col>
				)}

				{fieldValues?.type === PROMOCODE_TYPE.DISCOUNT && (
					<Col span={12}>
						<Field name={'argument'} label={t('Percentuálna zľava')} type={'number'} min={1} max={100} component={SliderField} required />
					</Col>
				)}

				{fieldValues?.lifespan === PROMOCODE_LIFESPAN.LONG_TERM && (
					<Col span={12}>
						<Field name={'maxUsageOfPromoCode'} label={t('Maximálny počet použití')} type={'number'} min={1} component={TextField} integer />
					</Col>
				)}

				{fieldValues?.type === PROMOCODE_TYPE.DISCOUNT && (
					<Col span={24}>
						<Row gutter={24}>
							<Col span={12}>
								<Field
									name={'targetDiscount'}
									component={SelectField}
									label={t('Typ členstva s nárokom na zľavu')}
									options={getPromoCodeTargetDiscountOptions(t)}
									multiple
									required
								/>
							</Col>
							{fieldValues?.targetDiscount?.includes(PROMO_CODE_TARGET_DISCOUNT.RECURRING) && (
								<Col span={12}>
									<Field
										name={'recurringPeriods'}
										component={TextField}
										type={'number'}
										label={t('Dĺžka trvania zľavy')}
										placeholder={t('Zadaj počet mesiacov')}
										min={1}
										suffix='mesiacov'
										required
									/>
								</Col>
							)}
						</Row>
					</Col>
				)}

				<Col span={12}>
					<Field name={'validFrom'} label={t('Platný od')} component={DateField} dttmFormat={'D.M.YYYY'} required />
				</Col>
				<Col span={12}>
					<Field name={'validTo'} label={t('Platný do')} component={DateField} dttmFormat={'D.M.YYYY'} required />
				</Col>
				<Col span={12}>
					<Field name={'count'} label={t('Počet promokódov')} type={'number'} min={1} max={5000} component={TextField} integer required />
				</Col>
				<Col span={12}>
					<Field name={'batch'} label={t('Označenie várky')} component={TextField} maxLength={255} required />
				</Col>
				<Col span={12}>
					<Field name={'state'} component={SelectField} label={t('Stav')} options={promoCodesStates} required />
				</Col>
				<Col span={12}>
					<Field name={'lifespan'} component={SelectField} label={t('Platnosť')} options={promoLifeSpan} required />
				</Col>
				<Col span={12}>
					<Field name={'sendTransactionalEmail'} component={SwitchField} label={t('Transakčný e-mail')} />
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<GeneratePromoCodesFormValues, GeneratePromoCodesFormProps>({
	form: FORMS.GENERATE_PROMO_CODES,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateGeneratePromoCodesForm
})(GeneratePromoCodesForm)
