import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// utils
import { SMS_STATUS, SMS_TYPE } from '../../../utils/enums'

export type PaymentsFormValues = {
	id: number
	firstName: string
	lastName: string
	contactEmail: string
	paypalData: any
	smsNotifications: {
		id: number
		type: SMS_TYPE | null
		smsID: string
		state: SMS_STATUS
	}[]
	eshopCode: string | null
}

export default (values: PaymentsFormValues) => {
	const errors: FormErrors<PaymentsFormValues> = {}

	if (!values.firstName) {
		errors.firstName = i18next.t('Toto pole je povinné')
	}

	if (!values.lastName) {
		errors.lastName = i18next.t('Toto pole je povinné')
	}

	if (!values.contactEmail) {
		errors.contactEmail = i18next.t('Toto pole je povinné')
	}

	return errors
}
