import { useEffect, useState, useCallback } from 'react'
import dayjs from 'dayjs'
import { Spin } from 'antd'
import { get, find, toNumber, round } from 'lodash'
import { initialize, getFormValues } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { addSubscription, transferSubscription, changeUserPasswordByAdmin, createPayment } from '../../redux/user/userActions'
import { getPreferences } from '../../redux/select/actions'
import { getSubscriptionPayments, getUserPayments, refundPayment } from '../../redux/payments/actions'
import { getUser, updateUser, getUserPrograms, updateUserProgram, createUserProgram, getUserProgram, getUserUserAgents } from '../../redux/users/actions'
import { getUserSubscriptions, getUserSubscriptionsTierValidate } from '../../redux/subscriptions/actions'

// utils
import { ENDPOINTS, FORMS, USER_ROLE, CHALLENGE_TYPE } from '../../utils/enums'
import { getReq } from '../../utils/request'

// types
import { CustomPaymentFormValues } from './forms/validateCustomPaymentForm'
import { GetUsersIdPayload, PatchUsersIdBody } from '../../redux/users/types'
import { IComputedMatch } from '../../types/interfaces'
import { PostPaymentsUserIdInvoiceBody } from '../../redux/payments/types'
import { UserFormValues } from './forms/validateUserForm'

// forms
import UserForm from './forms/UserForm'
import { MEASUREMENTS_TIME } from './forms/UserProgramForm'

// components
import CreateUserProgramModal from './components/CreateUserProgramModal'
import CustomPaymentModal from './components/CustomPaymentModal'
import EditSubscriptionModal from '../../components/EditSubscriptionModal'
import PasswordChangeModal from './components/PasswordChangeModal'
import RecipeBooksModal from './components/RecipeBooksModal/RecipeBooksModal'
import SubscriptionModal from '../../components/SubscriptionModal'
import SubscriptionPayments from './components/SubscriptionPayments'
import TransferSubscriptionModal from './components/TransferSubscriptionModal'

type DetailUserProps = {
	computedMatch: IComputedMatch
}

const DetailUser = ({ computedMatch }: DetailUserProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.USER_FORM)(state)) as UserFormValues
	const detail = useSelector((state: RootState) => state.users?.detail)
	const userProgram = useSelector((state: RootState) => state.users?.userProgram?.data)
	const allPrograms = useSelector((state: RootState) => state.programs?.list?.selectList)

	// states
	const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
	const [showTransferSubscriptionModal, setShowTransferSubscriptionModal] = useState(false)
	const [showEditSubscriptionModal, setShowEditSubscriptionModal] = useState(false)
	const [recipeBooksModalOpen, setRecipeBooksModalOpen] = useState(false)
	const [showPaymentsModal, setShowPaymentsModal] = useState(false)
	const [paymentModalOpen, setPaymentModalOpen] = useState(false)
	const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false)
	const [showCreateUserProgramModal, setShowCreateUserProgramModal] = useState(false)

	// constants
	const { id } = computedMatch.params

	const isLoading = detail?.isLoading

	const getAllLists = useCallback(() => {
		dispatch(getPreferences())
	}, [dispatch])

	const initEmptyDetailForm = useCallback(() => {
		const initValues = {
			order: 1
		}

		dispatch(initialize(FORMS.USER_FORM, initValues))
	}, [dispatch])

	const initDetailForm = useCallback(
		(data: GetUsersIdPayload) => {
			const initValues = {
				id: get(data, 'id'),
				firstName: get(data, 'firstName'),
				lastName: get(data, 'lastName'),
				role: get(data, 'role') === USER_ROLE.ADMIN,
				contactEmail: get(data, 'contactEmail'),
				phoneNumber: get(data, 'phoneNumber'),
				email: get(data, 'email'),
				isConfirmed: get(data, 'isConfirmed') ? t('Áno') : t('Nie'),
				subscription: get(data, 'subscription') ? t('Áno') : t('Nie'),
				gender: get(data, 'gender'),
				finishedAt: get(data, 'program.finishedAt'),
				startedAt: get(data, 'program.startedAt'),
				isCompetition: get(data, 'program.isCompetition', false) ? t('Áno') : t('Nie'),
				birthdate: get(data, 'birthdate') ? dayjs(get(data, 'birthdate')) : null,
				isPregnant: get(data, 'isPregnant', false),
				image: get(data, 'image'),
				preferences: get(data, 'preferences'),
				height: get(data, 'height'),
				order: get(data, 'order') || 1,
				lastLoginAt: data.lastLoginAt === null ? t('Používateľ ešte nebol prihlásený') : dayjs(data.lastLoginAt).format('H:mm:ss D. MMM YYYY '),
				socials: get(data, 'socials')
			}

			dispatch(initialize(FORMS.USER_FORM, initValues))
		},
		[dispatch, t]
	)

	useEffect(() => {
		if (id) {
			dispatch(
				getUser(id, (data) => {
					initDetailForm(data)
					dispatch(getUserPayments(id))
					dispatch(getUserPrograms(id))
					dispatch(getUserUserAgents(id))
					dispatch(getUserSubscriptionsTierValidate(id))
				})
			)
		} else {
			initEmptyDetailForm()
		}

		// eslint-disable-next-line no-use-before-define
		getAllLists()
	}, [dispatch, id])

	const handleUpdate = (body: PatchUsersIdBody) => {
		dispatch(updateUser(id, body, () => dispatch(getUser(id, (data) => initDetailForm(data)))))
	}

	const handleSubmit = (values: UserFormValues) => {
		const body = {
			firstName: get(values, 'firstName'),
			lastName: get(values, 'lastName'),
			role: get(values, 'role') ? USER_ROLE.ADMIN : USER_ROLE.USER,
			contactEmail: get(values, 'contactEmail'),
			phoneNumber: get(values, 'phoneNumber'),
			email: get(values, 'email'),
			gender: get(values, 'gender'),
			birthdate: values?.birthdate ? dayjs(values.birthdate).format('YYYY-MM-DD') : null,
			isPregnant: get(values, 'isPregnant'),
			height: get(values, 'height'),
			image: get(values, 'image')
		}

		if (id) {
			handleUpdate(body)
		}
	}

	const handleSwitchUser = async () => {
		try {
			const { data } = await getReq(ENDPOINTS.SWITCH_USER(get(detail, 'data.id')))
			window.open(`${data.url}`, '_blank')
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		}
	}

	const handleAddSubscription = useCallback(() => {
		setShowSubscriptionModal(true)
	}, [setShowSubscriptionModal])

	const handleShowPayments = (subscriptionID: number) => {
		dispatch(getSubscriptionPayments(subscriptionID, {}, () => setShowPaymentsModal(true)))
	}

	const handleSubmitSubscription = useCallback(
		(values: any) => {
			const extendedValues = {
				userID: get(detail, 'data.id'),
				tier: values?.tier,
				activeFrom: values?.activeFrom.valueOf(),
				activeTo: values?.activeTo.valueOf(),
				activateReason: values.activateReason,
				note: values?.note
			}
			const fetch = async () => {
				await dispatch(addSubscription(extendedValues))
				dispatch(
					getUser(get(detail, 'data.id'), (data) => {
						// eslint-disable-next-line no-use-before-define
						initDetailForm(data)
						dispatch(getUserPayments(id))
						dispatch(getUserSubscriptions(id))
					})
				)
			}
			fetch()
			setShowSubscriptionModal(false)
		},
		[setShowSubscriptionModal, detail]
	)

	const handleSubmitTransferSubscription = ({ newUserID }: any) => {
		dispatch(
			transferSubscription(id, { newSubscriptionUserID: newUserID }, () => {
				setShowTransferSubscriptionModal(false)
				dispatch(
					getUser(id, (data) => {
						initDetailForm(data)
						dispatch(getUserPayments(id))
						dispatch(getUserSubscriptions(id))
					})
				)
			})
		)
	}

	const handleSubmitCustomPayment = (values: CustomPaymentFormValues) => {
		const body: PostPaymentsUserIdInvoiceBody = {
			subscriptionType: values.subscriptionType as any,
			subscriptionTier: values.subscriptionTier,
			activeFrom: values.activeFrom,
			currency: values.currency,
			price: (values?.price || 0) * 100,
			note: values?.note,
			sendEmail: values.sendEmail
		}

		dispatch(
			createPayment(id, body, (url) => {
				window.open(url, '_blank')
				dispatch(getUserPayments(id))
				dispatch(getUserSubscriptions(id))
			})
		)
		setPaymentModalOpen(false)
	}

	const handleRefund = (paymentId: number) => dispatch(refundPayment(paymentId, () => dispatch(getUserPayments(id))))

	const submitPasswordChange = ({ newPassword }: any) => {
		dispatch(
			changeUserPasswordByAdmin(id, { newPassword }, () => {
				setShowPasswordChangeModal(false)
			})
		)
	}

	const handleSubmitUserProgram = (values: any, programId: number, isChallenge: boolean, challengeType: CHALLENGE_TYPE) => {
		// in case a challenge is added, not a program
		let selectedProgram = false
		if (!programId) {
			selectedProgram = find(allPrograms, ['value', get(values, 'program')])
		}

		const payload: any = {
			startedAt: get(values, 'startedAt', dayjs()),
			finishedAt: get(values, 'finishedAt', null),
			isCompetition: get(values, 'isCompetition', false),
			difficulty: values?.difficulty
		}

		if (isChallenge || get(selectedProgram, 'isChallenge')) {
			if (challengeType === CHALLENGE_TYPE.COMPETITIVE || get(selectedProgram, 'challengeType') === CHALLENGE_TYPE.COMPETITIVE) {
				const measurementsBefore = {
					image: get(values, 'imageBefore', null),
					weight: round(toNumber(get(values, 'weightBefore', null)), 2),
					hips: round(toNumber(get(values, 'hipsBefore', null)), 2),
					waist: round(toNumber(get(values, 'waistBefore', null)), 2),
					thigh: round(toNumber(get(values, 'thighBefore', null)), 2)
				}
				const measurementsAfter = {
					image: get(values, 'imageAfter', null),
					weight: round(toNumber(get(values, 'weightAfter', null)), 2),
					hips: round(toNumber(get(values, 'hipsAfter', null)), 2),
					waist: round(toNumber(get(values, 'waistAfter', null)), 2),
					thigh: round(toNumber(get(values, 'thighAfter', null)), 2)
				}
				payload.measurementsBefore = Object.values(measurementsBefore).every((measurement) => !measurement) ? null : measurementsBefore
				payload.measurementsAfter = Object.values(measurementsAfter).every((measurement) => !measurement) ? null : measurementsAfter
			} else if (challengeType === CHALLENGE_TYPE.HARMONIC || get(selectedProgram, 'challengeType') === CHALLENGE_TYPE.HARMONIC) {
				const questions = selectedProgram || userProgram
				const answersBefore = get(questions, 'questions', []).reduce((acc: any, question: any) => {
					const answerBeforeValue = values[`question-${MEASUREMENTS_TIME.BEFORE}-${question.id}-value`] || null
					const answerBeforeComment = values[`question-${MEASUREMENTS_TIME.BEFORE}-${question.id}-comment`] || null

					acc.push({
						questionID: question.id,
						value: answerBeforeValue,
						comment: answerBeforeComment
					})

					return acc
				}, [])

				const answersAfter = get(questions, 'questions', []).reduce((acc: any, question: any) => {
					const answerAfterValue = values[`question-${MEASUREMENTS_TIME.AFTER}-${question.id}-value`] || null
					const answerAfterComment = values[`question-${MEASUREMENTS_TIME.AFTER}-${question.id}-comment`] || null

					acc.push({
						questionID: question.id,
						value: answerAfterValue,
						comment: answerAfterComment
					})

					return acc
				}, [])

				payload.answersBefore = answersBefore
				payload.answersAfter = answersAfter
			}
		}

		if (!programId) {
			payload.programID = get(values, 'program', null)
		}

		if (programId) {
			dispatch(
				updateUserProgram(get(fieldValues, 'id'), programId, payload, () => {
					dispatch(getUserPrograms(get(fieldValues, 'id')))
					dispatch(getUserProgram(get(fieldValues, 'id'), programId))
				})
			)
		} else {
			dispatch(createUserProgram(fieldValues?.id, payload, () => dispatch(getUserPrograms(fieldValues?.id))))
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<UserForm
					isCreate={!id}
					onSubmit={handleSubmit}
					handleSwitchUser={handleSwitchUser}
					handleRefund={handleRefund}
					handleAddSubscription={handleAddSubscription}
					setShowTransferSubscriptionModal={setShowTransferSubscriptionModal}
					setShowEditSubscriptionModal={setShowEditSubscriptionModal}
					setShowRecipeBooksModal={setRecipeBooksModalOpen}
					setShowPasswordChangeModal={setShowPasswordChangeModal}
					showPayments={handleShowPayments}
					setShowPaymentModal={setPaymentModalOpen}
					handleShowCreateProgramModal={setShowCreateUserProgramModal}
					handleSubmitUserProgram={handleSubmitUserProgram}
				/>
				{showSubscriptionModal && <SubscriptionModal setShowModal={setShowSubscriptionModal} submitSubscription={handleSubmitSubscription} />}
				{showTransferSubscriptionModal && (
					<TransferSubscriptionModal setShowModal={setShowTransferSubscriptionModal} submitTransferSubscription={handleSubmitTransferSubscription} />
				)}
				{showEditSubscriptionModal && (
					<EditSubscriptionModal
						showEditSubscriptionModal={showEditSubscriptionModal}
						setShowEditSubscriptionModal={setShowEditSubscriptionModal}
						userID={id}
					/>
				)}
				<RecipeBooksModal open={recipeBooksModalOpen} setOpen={setRecipeBooksModalOpen} />
				{paymentModalOpen && (
					<CustomPaymentModal open={paymentModalOpen} setShowModal={setPaymentModalOpen} submitPayment={handleSubmitCustomPayment} />
				)}
				{showPaymentsModal && <SubscriptionPayments setShowPaymentsModal={setShowPaymentsModal} id={id} />}
				{showPasswordChangeModal && <PasswordChangeModal setShowModal={setShowPasswordChangeModal} submitPasswordChange={submitPasswordChange} />}
				{showCreateUserProgramModal && (
					<CreateUserProgramModal setShowModal={setShowCreateUserProgramModal} handleSubmitUserProgram={handleSubmitUserProgram} />
				)}
			</Spin>
		</div>
	)
}

export default DetailUser
