import toNumber from 'lodash/toNumber'
import { submit } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../../redux'

import { sendOrderRecipeBook, sendOrderInitialRecipeBook } from '../../../../redux/recipeBooks/actions'

// utils
import { FORMS, RECIPE_BOOK_TYPE } from '../../../../utils/enums'

// types
import { PostUsersUserIdNutritionPlansOrderBody, PostUsersUserIdNutritionPlansInitialOrderBody } from '../../../../redux/recipeBooks/types'

// forms
import RecipeBooksForm from '../../forms/RecipeBooksForm'
import { RecipeBooksFormValues } from '../../forms/valiateRecipeBooksForm'

// styles
import * as SC from './RecipeBooksModalStyles'

type RecipeBooksModalProps = {
	open: boolean
	setOpen: (arg: boolean) => void
}

const RecipeBooksModal = ({ open = true, setOpen }: RecipeBooksModalProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const userDetail = useSelector((state: RootState) => state.users?.detail?.data)

	const handleSubmit = (values: RecipeBooksFormValues) => {
		let body: PostUsersUserIdNutritionPlansInitialOrderBody | PostUsersUserIdNutritionPlansOrderBody

		if (values?.recipeBookType === RECIPE_BOOK_TYPE.INITIAL) {
			body = {
				goal: values.goal,
				height: toNumber(values.height),
				weight: values.weight,
				birthDate: values.birthDate.toString(),
				gender: values.gender,
				workDifficulty: values.workDifficulty,
				exerciseActivity: values.exerciseActivity,
				stress: values.stress,
				allergens: values?.allergens ? [values.allergens] : undefined,
				...(values?.exerciseActivityDetails && {
					exerciseActivityDetails: `${values?.exerciseActivityDetails}`
				})
			}
			dispatch(sendOrderInitialRecipeBook(userDetail?.id, body, () => setOpen(false)))
		} else if (values?.recipeBookType === RECIPE_BOOK_TYPE.COMMON) {
			body = {
				index: values.index
			}
			dispatch(sendOrderRecipeBook(userDetail?.id, body, () => setOpen(false)))
		}
	}

	return (
		<SC.StyledModal
			open={open}
			title={t('Žiadosť o vygenerovanie jedálnička')}
			okText={t('Odoslať')}
			onOk={() => {
				dispatch(submit(FORMS.RECIPE_BOOKS_FORM))
			}}
			cancelText={t('Zrušiť')}
			onCancel={() => setOpen(false)}
			destroyOnClose
		>
			<RecipeBooksForm onSubmit={handleSubmit} />
		</SC.StyledModal>
	)
}

export default RecipeBooksModal
