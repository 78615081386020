// utils
import { GetUrls, PostUrls, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type GetUsersUserIdNutritionPlansPayload = RequestResponse<GetUrls['/admin/users/{userID}/nutrition-plans/']>

// bodies
export type PostUsersUserIdNutritionPlansInitialOrderBody = RequestPayload<PostUrls['/admin/users/{userID}/nutrition-plans/initial-order']>
export type PostUsersUserIdNutritionPlansOrderBody = RequestPayload<PostUrls['/admin/users/{userID}/nutrition-plans/order']>

export enum GET_RECIPE_BOOKS {
	START = 'GET_RECIPE_BOOKS_START',
	DONE = 'GET_RECIPE_BOOKS_DONE',
	FAILED = 'GET_RECIPE_BOOKS_FAILED'
}

export enum SEND_ORDER_INITIAL_RECIPE_BOOK {
	START = 'SEND_ORDER_INITIAL_RECIPE_BOOK_START',
	DONE = 'SEND_ORDER_INITIAL_RECIPE_BOOK_DONE',
	FAILED = 'SEND_ORDER_INITIAL_RECIPE_BOOK_FAILED'
}

export enum SEND_ORDER_RECIPE_BOOK {
	START = 'SEND_ORDER_RECIPE_BOOK_START',
	DONE = 'SEND_ORDER_RECIPE_BOOK_DONE',
	FAILED = 'SEND_ORDER_RECIPE_BOOK_FAILED'
}
