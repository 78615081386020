import i18next from 'i18next'
import isEmpty from 'lodash/isEmpty'
import { FormErrors } from 'redux-form'

// utils
import { PROMO_CODE_TARGET_DISCOUNT, PROMOCODE_TYPE } from '../../../utils/enums'

// types
import { PostPromoCodesBatchBody } from '../../../redux/promoCodes/types'

export type GeneratePromoCodesFormValues = {
	targetDiscount: PROMO_CODE_TARGET_DISCOUNT[]
	recurringPeriods?: number
} & PostPromoCodesBatchBody

export default (values: GeneratePromoCodesFormValues) => {
	const { batch, codeLength, state, type, targetDiscount, recurringPeriods, argument, validFrom, validTo, count } = values
	const errors: FormErrors<GeneratePromoCodesFormValues> = {}

	if (!batch) {
		errors.batch = i18next.t('Toto pole je povinné')
	}

	if (!codeLength) {
		errors.codeLength = i18next.t('Toto pole je povinné')
	} else if (codeLength < 4 || codeLength > 10) {
		errors.codeLength = i18next.t('Minimum 4, maximum 10')
	}

	if (!state) {
		errors.state = i18next.t('Toto pole je povinné')
	}

	if (!type) {
		errors.type = i18next.t('Toto pole je povinné')
	}

	if (type && argument) {
		if (type === PROMOCODE_TYPE.DISCOUNT && !(argument >= 0 && argument <= 100)) {
			errors.argument = i18next.t('Zadajte číslo v rozsahu 0 až 100')
		} else if (!(argument >= 0 && argument <= 100)) {
			errors.argument = i18next.t('Zadajte číslo v rozsahu 0 až 100')
		} else if (type === PROMOCODE_TYPE.FREE_MONTHS && argument > 12) {
			errors.argument = i18next.t('Maximálna hodnota je 12 mesiacov')
		}
	}

	if (type === PROMOCODE_TYPE.DISCOUNT && isEmpty(targetDiscount)) {
		errors.targetDiscount = i18next.t('Toto pole je povinné')
	}

	if (type === PROMOCODE_TYPE.DISCOUNT && targetDiscount.includes(PROMO_CODE_TARGET_DISCOUNT.RECURRING) && !recurringPeriods) {
		errors.recurringPeriods = i18next.t('Toto pole je povinné')
	}

	if (recurringPeriods && recurringPeriods < 1) {
		errors.recurringPeriods = i18next.t('Minimálna hodnota je 1')
	}

	if (!validFrom) {
		errors.validFrom = i18next.t('Toto pole je povinné')
	}

	if (!validTo) {
		errors.validTo = i18next.t('Toto pole je povinné')
	}

	if (validFrom && validTo) {
		if (validFrom > validTo) {
			errors.validTo = i18next.t('Dátum konca platností musí byť väčší než začiatku')
		}
	}

	if (!count) {
		errors.count = i18next.t('Toto pole je povinné')
	} else if (count > 5000) {
		errors.count = i18next.t('Maximálny počet pre várku je 5000')
	}

	return errors
}
