import dayjs from 'dayjs'
import { FormErrors } from 'redux-form'
import i18next from 'i18next'

// utils
import { disabledBirthdate, MAX_HEIGHT, MAX_WEIGHT, MIN_HEIGHT, MIN_WEIGHT } from '../../../utils/helpers'
import {
	PLANEAT_ALLERGEN,
	PLANEAT_EXERCISE_ACTIVITY,
	PLANEAT_FITNESS_GOAL,
	PLANEAT_GENDER,
	PLANEAT_STRESS_LEVEL,
	PLANEAT_WORK_DIFFICULTY,
	RECIPE_BOOK_TYPE
} from '../../../utils/enums'

export type RecipeBooksFormValues = {
	recipeBookType: RECIPE_BOOK_TYPE
	goal: PLANEAT_FITNESS_GOAL
	height: number
	weight: number
	birthDate: Date
	gender: PLANEAT_GENDER
	workDifficulty: PLANEAT_WORK_DIFFICULTY
	exerciseActivity: PLANEAT_EXERCISE_ACTIVITY
	stress: PLANEAT_STRESS_LEVEL
	allergens?: PLANEAT_ALLERGEN
	exerciseActivityDetails?: any[]
	index: number
}

export default (values: RecipeBooksFormValues) => {
	const errors: FormErrors<RecipeBooksFormValues> = {}

	if (values.recipeBookType === RECIPE_BOOK_TYPE.INITIAL) {
		if (!values.goal) {
			errors.goal = i18next.t('Toto pole je povinné')
		}

		if (!values.height) {
			errors.height = i18next.t('Toto pole je povinné')
		}

		if (values.height < MIN_HEIGHT) {
			errors.height = i18next.t('Minimálna výška je 100 cm')
		}

		if (values.height > MAX_HEIGHT) {
			errors.height = i18next.t('Maximálna výška je 250 cm')
		}

		// eslint-disable-next-line no-bitwise
		if (values.height && !(~~values.height > 100 && ~~values.height < 250)) {
			errors.height = i18next.t('Výška mimo rozsah')
		}

		if (!values.weight) {
			errors.weight = i18next.t('Toto pole je povinné')
		}

		if (values.weight < MIN_WEIGHT) {
			errors.weight = i18next.t('Minimálna váha je 30 kg')
		}

		if (values.weight > MAX_WEIGHT) {
			errors.weight = i18next.t('Maximálna váha je 300 kg')
		}

		if (!values.birthDate) {
			errors.birthDate = i18next.t('Toto pole je povinné')
		}

		if (values.birthDate) {
			const isDisabled = disabledBirthdate(dayjs(values.birthDate))
			if (isDisabled) {
				errors.birthDate = i18next.t('Neplatný dátum')
			}
		}

		if (!values.gender) {
			errors.gender = i18next.t('Toto pole je povinné')
		}

		if (!values.workDifficulty) {
			errors.workDifficulty = i18next.t('Toto pole je povinné')
		}

		if (!values.exerciseActivity) {
			errors.exerciseActivity = i18next.t('Toto pole je povinné')
		}

		if (!values.stress) {
			errors.stress = i18next.t('Toto pole je povinné')
		}
	} else if (values?.recipeBookType === RECIPE_BOOK_TYPE.COMMON) {
		if (!values.index) {
			errors.index = i18next.t('Toto pole je povinné')
		}
	}

	return errors
}
