import dayjs, { Dayjs } from 'dayjs'
import find from 'lodash/find'
import get from 'lodash/get'
import i18next from 'i18next'
import map from 'lodash/map'
import round from 'lodash/round'
import { FormErrors } from 'redux-form'

// types
import { IFieldLabels } from '../types/interfaces'

// enums
import {
	EMPTY_VALUE,
	FREE_SUBSCRIPTION_REASON,
	LANGUAGE,
	PAYMENT_METHOD,
	PAYMENT_STATE,
	PAYMENT_TYPE,
	PLANEAT_ALLERGEN,
	PLANEAT_EXERCISE_ACTIVITY,
	PLANEAT_FITNESS_GOAL,
	PLANEAT_GENDER,
	PLANEAT_STRESS_LEVEL,
	PLANEAT_WORK_DIFFICULTY,
	PROGRAM_DIFFICULTY,
	PROMO_CODE_TARGET_DISCOUNT,
	PROMOCODE_LIFESPAN,
	PROMOCODE_STATE,
	PROMOCODE_TYPE,
	RECIPE_BOOK_TYPE,
	SMS_STATUS,
	SMS_TYPE,
	SUBSCRIPTION_STATE,
	SUBSCRIPTION_TIER,
	SUBSCRIPTION_TYPE,
	VIDEO_DIFFICULTY,
	VIDEO_MEDIUM_DIFFICULTY
} from './enums'

export const MIN_WEIGHT = 30
export const MAX_WEIGHT = 300

export const MIN_HEIGHT = 100
export const MAX_HEIGHT = 250

export const disabledBirthdate = (currentDate: Dayjs) => {
	const fifteenYearsAgo = dayjs().tz('Europe/Bratislava').subtract(15, 'years').endOf('day')
	const hundredYearsAgo = dayjs().tz('Europe/Bratislava').subtract(100, 'year').startOf('day')
	return currentDate.isAfter(fifteenYearsAgo) || currentDate.isBefore(hundredYearsAgo)
}

export const formatVideoTime = (time: number) => {
	const hours = Math.floor(time / 60 / 60)
	const minutes = Math.floor(time / 60) - hours * 60
	const seconds = Math.floor(time % 60)
	return `${hours > 0 ? `${hours}:` : ''}${minutes < 10 ? 0 : ''}${minutes}:${seconds < 10 ? 0 : ''}${seconds}`
}

const formatDaysTranslation = (days: number) => {
	switch (true) {
		case days === 1:
			return i18next.t('deň')
		case days >= 2 && days <= 4:
			return i18next.t('dni')
		default:
			return i18next.t('dní')
	}
}

export const convertCentsToEuros = (centsValue: number) => round(centsValue / 100, 2)

export const instructorDeclension = (count: number) => {
	switch (true) {
		case count === 0:
			return i18next.t('inštruktorov')
		case count === 1:
			return i18next.t('inštruktor')
		case count >= 2 && count <= 4:
			return i18next.t('inštruktori')
		case count >= 5:
			return i18next.t('inštruktorov')
		default:
			return i18next.t('inštruktorov')
	}
}

export const programDeclension = (count: number) => {
	switch (true) {
		case count === 0:
			return i18next.t('programov')
		case count === 1:
			return i18next.t('program')
		case count >= 2 && count <= 4:
			return i18next.t('programy')
		case count >= 5:
			return i18next.t('programov')
		default:
			return i18next.t('programov')
	}
}

export const getLanguageName = (language: string) => {
	switch (language) {
		case LANGUAGE.SK:
			return i18next.t('slovenčina')
		case LANGUAGE.CZ:
			return i18next.t('čeština')
		case LANGUAGE.EN:
			return i18next.t('angličtina')
		default:
			return language
	}
}

export const formatPromoCodeType = (type: string) => {
	switch (type) {
		case PROMOCODE_TYPE.DISCOUNT:
			return i18next.t('Percentuálna zľava')
		case PROMOCODE_TYPE.FREE_MONTHS:
			return i18next.t('Členstvo zadarmo')
		default:
			return EMPTY_VALUE
	}
}

export const getPromoCodesLifespan = (type: string) => {
	switch (type) {
		case PROMOCODE_LIFESPAN.LONG_TERM:
			return i18next.t('Dlhotrvajúci')
		case PROMOCODE_LIFESPAN.ONE_TIME:
			return i18next.t('Jednorazový')
		default:
			return type
	}
}

export const formatSubscriptionTier = (tier: SUBSCRIPTION_TIER) => {
	switch (tier) {
		case SUBSCRIPTION_TIER.PREMIUM:
			return i18next.t('Premium')
		case SUBSCRIPTION_TIER.VIP:
			return i18next.t('VIP')
		default:
			return tier
	}
}

export const formatSubscriptionState = (state: SUBSCRIPTION_STATE, daysToEnd: number) => {
	const formattedDaysTranslation = formatDaysTranslation(daysToEnd)

	switch (state) {
		case SUBSCRIPTION_STATE.INITIALIZED:
			return i18next.t('Inicializované')
		case SUBSCRIPTION_STATE.PREAUTHORIZED:
			return i18next.t('Preautorizované')
		case SUBSCRIPTION_STATE.DOWNGRADED:
			return i18next.t('Downgradované')
		case SUBSCRIPTION_STATE.UPGRADED:
			return i18next.t('Upgradované')
		case SUBSCRIPTION_STATE.ACTIVE:
			return i18next.t('Aktívne')
		case SUBSCRIPTION_STATE.CANCELED:
			if (daysToEnd > 0) {
				return i18next.t('Zrušené (zostáva {{daysToEnd}} {{formattedDaysTranslation}})', { daysToEnd, formattedDaysTranslation })
			}
			return i18next.t('Zrušené')
		case SUBSCRIPTION_STATE.FAILED:
			return i18next.t('Neúspešné')
		default:
			return state
	}
}

export const formatSubscriptionType = (type: string, paymentType?: string) => {
	switch (type) {
		case SUBSCRIPTION_TYPE.CUSTOM:
			return i18next.t('Bezplatné členské')
		case SUBSCRIPTION_TYPE.PROMOCODE:
			return i18next.t('Promo kód členské')
		case SUBSCRIPTION_TYPE.MONTH:
			if (paymentType === PAYMENT_TYPE.MANUAL) {
				return i18next.t('Jednorazové mesačné členské')
			}
			if (paymentType === PAYMENT_TYPE.REFUND) {
				return i18next.t('Refundované mesačné členské')
			}
			return i18next.t('Pravidelné mesačné členské')
		case SUBSCRIPTION_TYPE.ONETIME_MONTH:
			return i18next.t('Manuálne mesačné členské')
		case SUBSCRIPTION_TYPE.QUARTER:
			return i18next.t('Jednorazové štvrťročné členské')
		case SUBSCRIPTION_TYPE.YEAR:
			return i18next.t('Jednorazové ročné členské')
		case SUBSCRIPTION_TYPE.IOS_MONTH:
			return i18next.t('Pravidelné mesačné členské (iOS)')
		case SUBSCRIPTION_TYPE.IOS_QUARTER:
			return i18next.t('Jednorazové štvrťročné členské (iOS)')
		case SUBSCRIPTION_TYPE.IOS_YEAR:
			return i18next.t('Jednorazové ročné členské (iOS)')
		case SUBSCRIPTION_TYPE.ANDROID_MONTH:
			return i18next.t('Pravidelné mesačné členské (Android)')
		case SUBSCRIPTION_TYPE.ANDROID_QUARTER:
			return i18next.t('Jednorazové štvrťročné členské (Android)')
		case SUBSCRIPTION_TYPE.ANDROID_YEAR:
			return i18next.t('Jednorazové ročné členské (Android)')
		default:
			return type
	}
}

export const formatReasons = (type: string) => {
	switch (type) {
		case FREE_SUBSCRIPTION_REASON.FAILURE:
			return i18next.t('Chyba')
		case FREE_SUBSCRIPTION_REASON.ILLNESS:
			return i18next.t('Necvičil/a kvôli chorobe')
		case FREE_SUBSCRIPTION_REASON.PREGNANCY:
			return i18next.t('Necvičila kvôli tehotenstvu')
		case FREE_SUBSCRIPTION_REASON.PROMO:
			return i18next.t('Promo akcia')
		case FREE_SUBSCRIPTION_REASON.OTHER:
			return i18next.t('Iný dôvod')
		default:
			return type
	}
}

export const formatSMSStatus = (status: string) => {
	switch (status) {
		case SMS_STATUS.SUCCESS:
			return i18next.t('Úspešná')
		case SMS_STATUS.FAILED:
			return i18next.t('Neúspešná')
		default:
			return status
	}
}

export const formatSMSType = (type: string | null) => {
	switch (type) {
		case SMS_TYPE.ACTIVATION:
			return i18next.t('Aktivácia')
		case SMS_TYPE.RECURRING:
			return i18next.t('Opakujúca sa')
		case SMS_TYPE.NOTIFICATION:
			return i18next.t('Notifikácia')
		default:
			return type
	}
}

export const formatResultCode = (resultCode: string) => {
	switch (resultCode) {
		case '0':
			return i18next.t('Úspešná platba')
		case '1':
			return i18next.t('Čaká na spracovanie')
		case '2':
			return i18next.t('Oznámená')
		case '3':
			return i18next.t('Autorizovaná')
		case '4':
			return i18next.t('Spracováva sa')
		case '5':
			return i18next.t('Iba autorizovaná')
		case '1001':
			return i18next.t('Nesprávna požiadavka')
		case '1002':
			return i18next.t('Neznámy účet predajcu')
		case '1003':
			return i18next.t('Účet predajcu zablokovaný')
		case '1004':
			return i18next.t('Nesprávny podpis')
		case '1005':
			return i18next.t('Platba zrušená používateľom')
		case '1006':
			return i18next.t('Neplatná autentifikácia')
		case '1007':
			return i18next.t('Nedostatočný zostatok')
		case '1008':
			return i18next.t('Služba nie je povolená')
		case '1009':
			return i18next.t('ID procesu už bolo použité')
		case '1010':
			return i18next.t('Transakcia nenájdená')
		case '1011':
			return i18next.t('Nepodporovaná transakcia')
		case '1014':
			return i18next.t('Zamietnutá transakcia')
		case '1100':
			return i18next.t('Všeobecná chyba na strane TrustPay')
		case '1101':
			return i18next.t('Nepodporovaný prevod meny')
		default:
			return resultCode
	}
}

export const formatAcquirerResponseId = (id: string) => {
	switch (id) {
		case '000.400.101':
			return i18next.t('trustpayCardAcquirer|authenticationNotAvailable')
		case '000.400.102':
			return i18next.t('trustpayCardAcquirer|userNotRegistered')
		case '000.400.103':
			return i18next.t('trustpayCardAcquirer|technicalError3Dsecure')
		case '000.400.106':
			return i18next.t('trustpayCardAcquirer|authenticationFailed3Dsecure')
		case '000.400.107':
			return i18next.t('trustpayCardAcquirer|communicationErrorCardProvider')
		case '000.400.108':
			return i18next.t('trustpayCardAcquirer|unableToVerifyCardHolder')
		case '100.100.303':
			return i18next.t('trustpayCardAcquirer|cardExpired')
		case '100.100.304':
			return i18next.t('trustpayCardAcquirer|invalidCard')
		case '100.100.600':
			return i18next.t('trustpayCardAcquirer|invalidCVV')
		case '100.100.601':
			return i18next.t('trustpayCardAcquirer|invalidCVV')
		case '100.100.700':
			return i18next.t('trustpayCardAcquirer|invalidCardNumber')
		case '100.150.200':
			return i18next.t('trustpayCardAcquirer|userNotRegistered')
		case '100.380.401':
			return i18next.t('trustpayCardAcquirer|error3Dsecure')
		case '100.380.501':
			return i18next.t('trustpayCardAcquirer|authenticationError3Dsecure')
		case '100.390.105':
			return i18next.t('trustpayCardAcquirer|technicalIssue3Dsecure')
		case '100.390.112':
			return i18next.t('trustpayCardAcquirer|technicalIssue3Dsecure')
		case '100.396.101':
			return i18next.t('trustpayCardAcquirer|transactionCanceledByUser')
		case '100.396.102':
			return i18next.t('trustpayCardAcquirer|transactionNotConfirmedByUser')
		case '700.400.200':
			return i18next.t('trustpayCardAcquirer|invalidRefund')
		case '800.100.100':
			return i18next.t('trustpayCardAcquirer|transactionCanceledUnknownReason')
		case '800.100.151':
			return i18next.t('trustpayCardAcquirer|invalidCardNumber')
		case '800.100.152':
			return i18next.t('trustpayCardAcquirer|transactionCanceledByAuthenticationSystem')
		case '800.100.153':
			return i18next.t('trustpayCardAcquirer|invalidCVV')
		case '800.100.154':
			return i18next.t('trustpayCardAcquirer|transactionInvalid')
		case '800.100.155':
		case '800.100.203':
			return i18next.t('trustpayCardAcquirer|notEnoughMoney')
		case '800.100.157':
			return i18next.t('trustpayCardAcquirer|invalidCardExpirationDate')
		case '800.100.158':
			return i18next.t('trustpayCardAcquirer|transactionSuspicious')
		case '800.100.159':
			return i18next.t('trustpayCardAcquirer|cardStolen')
		case '800.100.160':
			return i18next.t('trustpayCardAcquirer|cardBlocked')
		case '800.100.161':
			return i18next.t('trustpayCardAcquirer|tooManyInvalidRequests')
		case '800.100.162':
			return i18next.t('trustpayCardAcquirer|cardLimitExceeded')
		case '800.100.163':
			return i18next.t('trustpayCardAcquirer|cardLimitExceeded')
		case '800.100.165':
			return i18next.t('trustpayCardAcquirer|cardStolen')
		case '800.100.168':
			return i18next.t('trustpayCardAcquirer|cardRestricted')
		case '800.100.171':
			return i18next.t('trustpayCardAcquirer|invalidCard')
		case '800.100.172':
			return i18next.t('trustpayCardAcquirer|accountBlocked')
		case '800.100.173':
			return i18next.t('trustpayCardAcquirer|invalidCurrency')
		case '800.100.174':
			return i18next.t('trustpayCardAcquirer|invalidCurrency')
		case '800.100.175':
			return i18next.t('trustpayCardAcquirer|invalidCurrencySymbol')
		case '800.100.176':
			return i18next.t('trustpayCardAcquirer|accountTemporaryUnaccessable')
		case '800.100.177':
			return i18next.t('trustpayCardAcquirer|transactionCanceledIncorrectPrice')
		case '800.100.192':
			return i18next.t('trustpayCardAcquirer|invalidCVV')
		case '800.100.202':
			return i18next.t('trustpayCardAcquirer|accountClosed’')
		case '800.120.101':
			return i18next.t('trustpayCardAcquirer|cardLimitExceeded')
		case '800.120.200':
			return i18next.t('trustpayCardAcquirer|cardLimitExceeded')
		case '800.120.201':
			return i18next.t('trustpayCardAcquirer|cardLimitExceeded')
		case '800.300.501':
			return i18next.t('trustpayCardAcquirer|tooManyInvalidRequests')
		case '800.700.100':
			return i18next.t('trustpayCardAcquirer|transactionBeingProcessed')
		case '800.900.300':
			return i18next.t('trustpayCardAcquirer|unableToVerifyCardHolder')
		case '999.999.888':
			return i18next.t('trustpayCardAcquirer|unableToVerifyCardHolder')
		case 'UNKNOWN_REASON':
			return i18next.t('trustpayCardAcquirer|unknownReason')
		default:
			return id
	}
}

export const formatPromoCodeState = (type: string) => {
	switch (type) {
		case PROMOCODE_STATE.ACTIVE:
			return i18next.t('Aktívny')
		case PROMOCODE_STATE.INACTIVE:
			return i18next.t('Neaktívny')
		default:
			return EMPTY_VALUE
	}
}

export const formatPromoCodeLifespan = (type: string) => {
	switch (type) {
		case PROMOCODE_LIFESPAN.LONG_TERM:
			return i18next.t('Dlhotrvajúci')
		case PROMOCODE_LIFESPAN.ONE_TIME:
			return i18next.t('Jednorazový')
		default:
			return EMPTY_VALUE
	}
}

export const formatPaymentMethod = (method: string) => {
	switch (method) {
		case PAYMENT_METHOD.PAYPAL:
			return i18next.t('PayPal')
		case PAYMENT_METHOD.SMS:
			return i18next.t('SMS')
		case PAYMENT_METHOD.TRUSTPAY_CARD:
			return i18next.t('TrustPay - karta')
		case PAYMENT_METHOD.TRUSTPAY_BANK:
			return i18next.t('TrustPay - banka')
		case PAYMENT_METHOD.BANK_TRANSACTION:
			return i18next.t('Bankový prevod')
		case PAYMENT_METHOD.IOS_IN_APP_PURCHASE:
			return i18next.t('Platba v iOS aplikácii')
		case PAYMENT_METHOD.ANDROID_IN_APP_PURCHASE:
			return i18next.t('Platba v Android aplikácii')
		default:
			return method
	}
}

export const formatPaymentType = (type: string, subType?: string) => {
	switch (type) {
		case PAYMENT_TYPE.ONETIME:
			return i18next.t('Jednorazová')
		case PAYMENT_TYPE.ONETIME_MONTH:
			return i18next.t('Manuálne mesačne členské')
		case PAYMENT_TYPE.ONETIME_QUARTER:
			return i18next.t('Manuálne štvrťročné členské')
		case PAYMENT_TYPE.ONETIME_YEAR:
			return i18next.t('Manuálne ročné členské')
		case PAYMENT_TYPE.MONTH:
			if (subType === SUBSCRIPTION_TYPE.MONTH) {
				return i18next.t('Jednorazové mesačné členské')
			}
			return i18next.t('Pravidelné mesačné členské')
		case PAYMENT_TYPE.SUBSCRIPTION_INITIAL:
			return i18next.t('Inicializačná platba')
		case PAYMENT_TYPE.SUBSCRIPTION_RECURING:
			return i18next.t('Opakujúca sa')
		case PAYMENT_TYPE.REFUND:
			return i18next.t('Refundácia')
		case PAYMENT_TYPE.MANUAL:
			return i18next.t('Manuálna')
		case PAYMENT_TYPE.IOS_MONTH:
			return i18next.t('Pravidelné mesačné členské (iOS)')
		case PAYMENT_TYPE.IOS_QUARTER:
			return i18next.t('Jednorazové štvrťročné členské (iOS)')
		case PAYMENT_TYPE.IOS_YEAR:
			return i18next.t('Jednorazové ročné členské (iOS)')
		case PAYMENT_TYPE.ANDROID_MONTH:
			return i18next.t('Pravidelné mesačné členské (Android)')
		case PAYMENT_TYPE.ANDROID_QUARTER:
			return i18next.t('Jednorazové štvrťročné členské (Android)')
		case PAYMENT_TYPE.ANDROID_YEAR:
			return i18next.t('Jednorazové ročné členské (Android)')
		case PAYMENT_TYPE.SUBSCRIPTION_UPGRADE:
			return i18next.t('Upgradované členské')
		default:
			return type
	}
}

export const formatPaymentState = (state: string, isRefunded?: boolean, type?: string) => {
	switch (state) {
		case PAYMENT_STATE.INITIALIZED:
			return i18next.t('Inicializovaná')
		case PAYMENT_STATE.FINISHED:
			if (isRefunded) return i18next.t('Refundovaná')
			if (type === PAYMENT_TYPE.REFUND) return i18next.t('Refundácia')
			return i18next.t('Dokončená')
		case PAYMENT_STATE.FAILED:
			return i18next.t('Neúspešná')
		case PAYMENT_STATE.PREAUTHORIZED:
			return i18next.t('Autorizácia')
		default:
			return state
	}
}

export const getPaymentStateColor = (state: string, isRefunded: boolean, type?: string) => {
	switch (state) {
		case PAYMENT_STATE.INITIALIZED:
			return '#f39c12'
		case PAYMENT_STATE.FINISHED:
			if (isRefunded) return '#464646'
			if (type === PAYMENT_TYPE.REFUND) return '#4f8351'
			return '#79D17C'
		case PAYMENT_STATE.FAILED:
			return '#ff4d4f'
		case PAYMENT_STATE.PREAUTHORIZED:
			return '#3498db'
		default:
			return state
	}
}

export const formatNutritionals = (type: string) => {
	switch (type) {
		case 'recipeNutritionalCarbs':
			return i18next.t('Sacharidy')
		case 'recipeNutritionalFats':
			return i18next.t('Tuky')
		case 'recipeNutritionalFiber':
			return i18next.t('Vláknina')
		case 'recipeNutritionalGlycemic':
			return i18next.t('Glykemická nálož na 100g')
		case 'recipeNutritionalKcal':
			return i18next.t('Kalorická hodnota [kcal]')
		case 'recipeNutritionalKj':
			return i18next.t('Kalorická hodnota [kj]')
		case 'recipeNutritionalProteins':
			return i18next.t('Bielkoviny')
		case 'recipeNutritionalNote':
			return i18next.t('Poznámka')
		case 'recipeNutritionalInfoFor':
			return i18next.t('Výživové hodnoty')
		default:
			return type
	}
}

export const formatProgramDifficulty = (difficulty: PROGRAM_DIFFICULTY) => {
	switch (difficulty) {
		case PROGRAM_DIFFICULTY.NORMAL:
			return i18next.t('Štandarná')
		case PROGRAM_DIFFICULTY.HARD:
			return i18next.t('Náročnejšia')
		default:
			return difficulty
	}
}

export const setFiltersForPage = (page: string, persistFilter: any) => {
	let filters = {}
	if (persistFilter.page === page) {
		filters = persistFilter?.filters
	}

	return filters
}

export const getErrorFieldsLabel = (formErrors: FormErrors, getFieldLabel: (field: string) => string) =>
	map(Object.keys(formErrors), (field) => getFieldLabel(field))
		.filter(Boolean)
		.join(', ')

export const onlyNumbers = (e: any, decimal = false) => {
	if (decimal) {
		if (find(['.', ','], (k) => get(e, 'key') === k)) e.preventDefault()
	}
	if (find(['-', '+', 'e', "'"], (k) => get(e, 'key') === k)) e.preventDefault()
}

export const allowNumber = (evt: any) => {
	let curEvent = evt
	curEvent = curEvent || get(window, 'event')
	const charCode = get(curEvent, 'which') ? get(curEvent, 'which') : get(curEvent, 'keyCode')
	if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		evt.preventDefault()
	}
}

export const disabledDate = (current: any) => {
	return current && current > dayjs().startOf('day')
}

export const hashCode = (str: string) => {
	let hash = 0
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < str.length; i++) {
		// eslint-disable-next-line no-bitwise
		hash = str.charCodeAt(i) + ((hash << 5) - hash)
	}
	return hash
}

export const intToRGB = (hash: number) => {
	// eslint-disable-next-line no-bitwise
	const c = (hash & 0x00ffffff).toString(16).toUpperCase()
	return '00000'.substring(0, 6 - c.length) + c
}

export const getFieldLabel = (field: string, fieldLabels: IFieldLabels): string => fieldLabels[field] || field

export const circlePickerColors = [
	'#EDEDED', // secondary
	'#FFF1F2', // destructive
	'#D1FAE5', // success
	'#FFC08D', // warning
	'#FFE4E6', // error
	'#88CFFF', // info
	'#C0C0FF', // help
	'#91DFD2', // harmony
	'#88CFFF', // new year
	'#FFE0AC', // summer
	'#EFC2C2' // restart
]

// SelectField options
export const getSubscriptionTierTypes = (t: any, disabledTier?: any): { value: SUBSCRIPTION_TIER; title: string; disabled: boolean }[] => [
	{ value: SUBSCRIPTION_TIER.PREMIUM, title: t('Premium'), disabled: disabledTier === SUBSCRIPTION_TIER.PREMIUM },
	{ value: SUBSCRIPTION_TIER.VIP, title: t('VIP'), disabled: disabledTier === SUBSCRIPTION_TIER?.VIP }
]

export const getRecipeBookTypeOptions = (t: any, disabled: boolean): { value: RECIPE_BOOK_TYPE; label: string; disabled: boolean }[] => [
	{ value: RECIPE_BOOK_TYPE.INITIAL, label: t('Nový jedálniček'), disabled: false },
	{ value: RECIPE_BOOK_TYPE.COMMON, label: t('Pravidelný jedálniček'), disabled }
]

export const getPlaneatGoalOptions = (t: any): { value: PLANEAT_FITNESS_GOAL; label: string }[] => [
	{ value: PLANEAT_FITNESS_GOAL.LOSE_WEIGHT, label: t('Znížiť hmotnosť') },
	{ value: PLANEAT_FITNESS_GOAL.MODERATE_LOSE_WEIGHT, label: t('Mierne znížiť hmotnosť') },
	{ value: PLANEAT_FITNESS_GOAL.MAINTAIN_WEIGHT, label: t('Udržať hmotnosť') },
	{ value: PLANEAT_FITNESS_GOAL.MODERATE_GAIN_WEIGHT, label: t('Mierne zvýšiť hmotnosť') },
	{ value: PLANEAT_FITNESS_GOAL.GAIN_WEIGHT, label: t('Zvýšiť hmotnosť') }
]

export const getPlaneatGenderOptions = (t: any): { value: PLANEAT_GENDER; label: string }[] => [
	{ value: PLANEAT_GENDER.FEMALE, label: t('Žena') },
	{ value: PLANEAT_GENDER.MALE, label: t('Muž') }
]

export const getPlaneatWorkDifficultyOptions = (t: any): { value: PLANEAT_WORK_DIFFICULTY; label: string }[] => [
	{ value: PLANEAT_WORK_DIFFICULTY.VERY_EASY, label: t('Nenáročná (napr úradník)') },
	{ value: PLANEAT_WORK_DIFFICULTY.EASY, label: t('Mierne náročná (napr predavač)') },
	{ value: PLANEAT_WORK_DIFFICULTY.MEDIUM, label: t('Stredne náročná (napr kuchár)') },
	{ value: PLANEAT_WORK_DIFFICULTY.HIGH, label: t('Náročná (napr zvárač)') },
	{ value: PLANEAT_WORK_DIFFICULTY.VERY_HIGH, label: t('Veľmi náročná (napr murár)') }
]

export const getPlaneatExerciseActivityOptions = (t: any): { value: PLANEAT_EXERCISE_ACTIVITY; label: string }[] => [
	{ value: PLANEAT_EXERCISE_ACTIVITY.NO_EXERCISE, label: t('0 dní v týždni') },
	{ value: PLANEAT_EXERCISE_ACTIVITY.LIGHT_EXERCISE, label: t('1-3 dni v týždni') },
	{ value: PLANEAT_EXERCISE_ACTIVITY.MODERATE_EXERCISE, label: t('4-5 dní v týždni') },
	{ value: PLANEAT_EXERCISE_ACTIVITY.HEAVY_EXERCISE, label: t('6-7 dní v týždni') },
	{ value: PLANEAT_EXERCISE_ACTIVITY.VERY_HEAVY_EXERCISE, label: t('2x do dňa a viac') }
]

export const getPlaneatStressLevelOptions = (t: any): { value: PLANEAT_STRESS_LEVEL; label: string }[] => [
	{ value: PLANEAT_STRESS_LEVEL.LOW, label: t('Nízky') },
	{ value: PLANEAT_STRESS_LEVEL.MEDIUM, label: t('Stredný') },
	{ value: PLANEAT_STRESS_LEVEL.HIGH, label: t('Vysoký') }
]

export const getPlaneatAllergensOptions = (t: any): { value: string; label: string }[] => [
	{ value: PLANEAT_ALLERGEN.MEAT, label: t('Mäso') },
	{ value: PLANEAT_ALLERGEN.LACTOSE, label: t('Laktóza') },
	{ value: PLANEAT_ALLERGEN.GLUTEN, label: t('Lepok') },
	{ value: PLANEAT_ALLERGEN.NUTS, label: t('Orechy') },
	{ value: PLANEAT_ALLERGEN.SOY, label: t('Sója') }
]

export const getPlaneatIndexOptions = (): { value: number; label: number }[] => [
	{ value: 1, label: 1 },
	{ value: 2, label: 2 },
	{ value: 3, label: 3 },
	{ value: 4, label: 4 },
	{ value: 5, label: 5 },
	{ value: 6, label: 6 }
]

export const getProgramDifficultyOptions = (t: any, difficulties?: PROGRAM_DIFFICULTY[]): { value: PROGRAM_DIFFICULTY; label: string }[] => [
	{ value: PROGRAM_DIFFICULTY.NORMAL, label: t('Štandarná') },
	...(difficulties && difficulties.find((item) => item === PROGRAM_DIFFICULTY.HARD) ? [{ value: PROGRAM_DIFFICULTY.HARD, label: t('Náročnejšia') }] : [])
]

export const getVideoDifficultyOptions = (t: any): { value: VIDEO_DIFFICULTY; label: string }[] => [
	{ value: VIDEO_DIFFICULTY.EDUCATIONAL, label: t('Náučná') },
	{ value: VIDEO_DIFFICULTY.EASY, label: t('Ľahká') },
	{ value: VIDEO_DIFFICULTY.MEDIUM, label: t('Stredná') },
	{ value: VIDEO_DIFFICULTY.HARD, label: t('Ťažká') }
]

export const getVideoMediumDifficultyOptions = (t: any): { value: VIDEO_MEDIUM_DIFFICULTY; label: string }[] => [
	{ value: VIDEO_MEDIUM_DIFFICULTY.LIGHT, label: t('Stredne ľahká') },
	{ value: VIDEO_MEDIUM_DIFFICULTY.HARD, label: t('Stredne ťažká') }
]

export const getPromoCodeTargetDiscountOptions = (t: any): { value: PROMO_CODE_TARGET_DISCOUNT; label: string }[] => [
	{ value: PROMO_CODE_TARGET_DISCOUNT.ONE_TIME, label: t('Jednorazové') },
	{ value: PROMO_CODE_TARGET_DISCOUNT.RECURRING, label: t('Opakujúce sa') }
]
