import mapValues from 'lodash/mapValues'
import { Dispatch } from 'redux'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { getReq, postReq } from '../../utils/request'

// types
import {
	GET_RECIPE_BOOKS,
	GetUsersUserIdNutritionPlansPayload,
	PostUsersUserIdNutritionPlansOrderBody,
	PostUsersUserIdNutritionPlansInitialOrderBody,
	SEND_ORDER_RECIPE_BOOK,
	SEND_ORDER_INITIAL_RECIPE_BOOK
} from './types'

export const getRecipeBooks = (id: number, onSuccess?: () => void, onFailure?: (error?: any) => void) => async (dispatch: Dispatch) => {
	dispatch({ type: GET_RECIPE_BOOKS.START })
	let payload = {} as GetUsersUserIdNutritionPlansPayload
	try {
		const { data } = await getReq(ENDPOINTS.GET_RECIPE_BOOKS(id))
		payload = { standardNutritionPlan: data?.standardNutritionPlan }
		dispatch({ type: GET_RECIPE_BOOKS.DONE, payload })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: GET_RECIPE_BOOKS.FAILED })
		return onFailure && onFailure(error)
	}
}

export const sendOrderInitialRecipeBook =
	(id: number, body: PostUsersUserIdNutritionPlansInitialOrderBody, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: Dispatch) => {
		dispatch({ type: SEND_ORDER_INITIAL_RECIPE_BOOK.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query || undefined)
			await postReq(ENDPOINTS.ODRED_INITIAL_RECIPE_BOOK(id), undefined, normalizeQueryParams)
			dispatch({ type: SEND_ORDER_INITIAL_RECIPE_BOOK.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: SEND_ORDER_INITIAL_RECIPE_BOOK.FAILED })
			return onFailure && onFailure()
		}
	}

export const sendOrderRecipeBook =
	(id: number, body: PostUsersUserIdNutritionPlansOrderBody, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: Dispatch) => {
		dispatch({ type: SEND_ORDER_RECIPE_BOOK.START })
		try {
			await postReq(ENDPOINTS.ORDER_RECIPE_BOOK(id), undefined, body)
			dispatch({ type: SEND_ORDER_RECIPE_BOOK.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: SEND_ORDER_RECIPE_BOOK.FAILED })
			return onFailure && onFailure()
		}
	}
