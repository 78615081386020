import { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import i18next from 'i18next'
import map from 'lodash/map'
import toInteger from 'lodash/toInteger'
import { Button, Col, Empty, Input, Modal, Popconfirm, Row, Select, Spin, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Link, useHistory } from 'react-router-dom'
import { isInvalid, isPristine, submit } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
	CloseCircleOutlined,
	LinkOutlined,
	PauseCircleOutlined,
	PlayCircleOutlined,
	PlusCircleOutlined,
	QuestionCircleOutlined,
	SettingOutlined
} from '@ant-design/icons'

// redux
import { RootState } from '../../redux'
import { setFilters } from '../../redux/filters/actions'
import { generatePromoCodes, getPromoCodes, resetList, updatePromoCode } from '../../redux/promoCodes/actions'

// utils
import { formatPromoCodeLifespan, formatPromoCodeState, formatPromoCodeType, setFiltersForPage } from '../../utils/helpers'
import {
	EMPTY_VALUE,
	FORMS,
	INPUT_MAX_LENGTH,
	PROMO_CODE_TARGET_DISCOUNT,
	PROMOCODE_LIFESPAN,
	PROMOCODE_LIFESPANS,
	PROMOCODE_STATE,
	PROMOCODE_TYPE,
	PROMOCODE_TYPES
} from '../../utils/enums'

// types
import { GeneratePromoCodesFormValues } from './forms/validateGeneratePromoCodesForm'
import { GetPromoCodesListItemPayload, PostPromoCodesBatchBody, PutPromoCodesCodeIdBody } from '../../redux/promoCodes/types'

// components
import GeneratePromoCodesForm from './forms/GeneratePromoCodesForm'

// atoms
import BooleanRepresent from '../../atoms/BooleanRepresent'

const PAGE_SIZE = 20

const PromoCodes = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const persistFilter = useSelector((state: RootState) => state.filters)
	const pristine = useSelector((state: RootState) => isPristine(FORMS.GENERATE_PROMO_CODES)(state))
	const invalid = useSelector((state: RootState) => isInvalid(FORMS.GENERATE_PROMO_CODES)(state))
	const dataSource = useSelector((state: RootState) => state.promoCodes?.list?.tableList)
	const isLoadingList = useSelector((state: RootState) => state.promoCodes?.list?.isLoading)
	const context = useSelector((state: RootState) => state.promoCodes?.list?.context)

	// states
	const [generateModalOpen, setGenerateModalOpen] = useState(false)
	const [isGenerating, setIsGenerating] = useState(false)
	const [filter, setFilter] = useState<any>(setFiltersForPage(t('paths:promoCodes|key'), persistFilter))

	useEffect(() => {
		dispatch(resetList())
	}, [dispatch])

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter
		}

		dispatch(getPromoCodes(body))

		dispatch(setFilters(t('paths:promoCodes|key'), body))
	}, [filter, dispatch, t])

	const columns = [
		{
			title: i18next.t('Kód'),
			dataIndex: 'code',
			key: 'code',
			sorter: true,
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Status'),
			dataIndex: 'state',
			key: 'state',
			ellipsis: true,
			render: (value: string) => formatPromoCodeState(value)
		},
		{
			title: i18next.t('Platnosť'),
			key: 'validity',
			align: 'center',
			render: (_: any, record: GetPromoCodesListItemPayload) => {
				const { validFrom } = record
				const { validTo } = record
				const today = dayjs().format('YYYY-MM-DD')

				return <BooleanRepresent isTrue={!dayjs(today).isBefore(dayjs(validFrom)) && !dayjs(today).isAfter(dayjs(validTo))} />
			}
		},
		{
			title: i18next.t('Typ'),
			dataIndex: 'type',
			sorter: true,
			key: 'type',
			render: (value: string) => formatPromoCodeType(value)
		},
		{
			title: i18next.t('Hodnota'),
			dataIndex: 'argument',
			sorter: true,
			key: 'argument',
			render: (value: any) => {
				switch (value.type) {
					case PROMOCODE_TYPE.DISCOUNT:
						return `${value.arg * 100} %`
					case PROMOCODE_TYPE.FREE_MONTHS:
						return `${value.arg} ${t('mesiace', { count: value.arg })}`
					default:
						return EMPTY_VALUE
				}
			}
		},
		{
			title: i18next.t('Typ platnosti'),
			dataIndex: 'lifespan',
			key: 'lifespan',
			render: (value: string) => formatPromoCodeLifespan(value)
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 100,
			render: (_: any, record: GetPromoCodesListItemPayload & { key: number; argument: any }) => {
				const title = record.state === PROMOCODE_STATE.ACTIVE ? t('Skutočne chcete deaktivovať promo kód?') : t('Skutočne chcete aktivovať promo kód?')
				const okText = record.state === PROMOCODE_STATE.ACTIVE ? t('Deaktivovať') : t('Aktivovať')
				const newState = record.state === PROMOCODE_STATE.ACTIVE ? PROMOCODE_STATE.INACTIVE : PROMOCODE_STATE.ACTIVE
				const icon = record.state === PROMOCODE_STATE.ACTIVE ? <PauseCircleOutlined /> : <PlayCircleOutlined />

				return (
					<>
						<span style={{ marginRight: '5px' }}>
							<Link to={`${t('paths:promoCodeDetail|path')}/${get(record, 'key')}`} target={'_blank'} rel={'noopener noreferrer'}>
								<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
							</Link>
						</span>
						<Popconfirm
							title={title}
							icon={<QuestionCircleOutlined style={{ color: 'green' }} />}
							cancelText={t('Zrušiť')}
							okText={okText}
							onConfirm={(e: any) => {
								e.stopPropagation()
								const deactivateBody = {
									code: get(record, 'code'),
									state: newState,
									type: get(record, 'type'),
									forNewcomers: get(record, 'forNewcomers'),
									argument: get(record, 'argument').arg,
									validFrom: get(record, 'validFrom'),
									validTo: get(record, 'validTo')
								}

								dispatch(
									updatePromoCode(record.key, deactivateBody as PutPromoCodesCodeIdBody, () => {
										dispatch(
											getPromoCodes({
												limit: PAGE_SIZE,
												page: 1,
												...filter
											})
										)
									})
								)
							}}
							onCancel={(e: any) => e.stopPropagation()}
							okButtonProps={{
								size: 'small',
								type: 'primary'
							}}
							cancelButtonProps={{
								size: 'small',
								type: 'ghost'
							}}
						>
							<Button icon={icon} type={'primary'} onClick={(e) => e.stopPropagation()} />
						</Popconfirm>
					</>
				)
			}
		}
	]

	const promoStates = map(PROMOCODE_STATE, (item) => ({
		value: item,
		label: formatPromoCodeState(item)
	}))
	const promoTypes = map(PROMOCODE_TYPES, (item) => ({
		value: item,
		label: formatPromoCodeType(item)
	}))
	const promoLifespan = map(PROMOCODE_LIFESPANS, (item) => ({
		value: item,
		label: formatPromoCodeLifespan(item)
	}))
	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current,
			...order
		})
	}

	const debounced = useCallback(
		debounce((searchTerm) => setFilter({ ...filter, search: searchTerm, page: 1 }), 300),
		[filter]
	)

	const handleOnChange = (e: any) => {
		debounced(e.target.value)
	}

	const resetFilter = () => {
		setFilter({})
	}

	const handleGeneratePromoCodes = (values: GeneratePromoCodesFormValues) => {
		const { batch } = values

		const body: PostPromoCodesBatchBody = {
			batch,
			codeLength: values.codeLength,
			prefix: values.prefix,
			forNewcomers: values.forNewcomers,
			premiumTierAllowed: values.premiumTierAllowed,
			vipTierAllowed: values.vipTierAllowed,
			state: values.state,
			type: values.type,
			maxUsageOfPromoCode: values.lifespan === PROMOCODE_LIFESPAN.LONG_TERM && values?.maxUsageOfPromoCode ? values.maxUsageOfPromoCode : null,
			lifespan: values.lifespan,
			argument: values.type === PROMOCODE_TYPE.DISCOUNT && values?.argument ? values.argument / 100 : toInteger(values?.argument),
			onetime: values?.targetDiscount?.includes(PROMO_CODE_TARGET_DISCOUNT.ONE_TIME),
			recurring: values?.targetDiscount?.includes(PROMO_CODE_TARGET_DISCOUNT.RECURRING),
			recurringPeriods: values?.targetDiscount?.includes(PROMO_CODE_TARGET_DISCOUNT.RECURRING) ? toInteger(values?.recurringPeriods) : undefined,
			sendTransactionalEmail: values.sendTransactionalEmail,
			validFrom: values.validFrom,
			validTo: values.validTo,
			count: values.count
		}

		setIsGenerating(true)
		dispatch(
			generatePromoCodes(
				body,
				(csvData) => {
					const csvContent = `data:text/csv;charset=utf-8,${csvData}`
					const encodedUri = encodeURI(csvContent)
					const link = document.createElement('a')
					link.setAttribute('href', encodedUri)
					link.setAttribute('download', `export-${batch}.csv`)
					document.body.appendChild(link)
					link.click()
					setIsGenerating(false)
					setGenerateModalOpen(false)
				},
				() => setIsGenerating(false)
			)
		)
	}

	return (
		<div className={'page-wrapper'}>
			<Row gutter={[8, 8]} justify={'space-between'}>
				<Col>
					<Input.Search onChange={handleOnChange} defaultValue={filter?.search} maxLength={INPUT_MAX_LENGTH} style={{ width: 300 }} allowClear />
				</Col>

				<Col>
					<Row gutter={[8, 8]}>
						<Col>
							<Button icon={<SettingOutlined />} type={'primary'} onClick={() => setGenerateModalOpen(true)}>
								{t('Generovať promokódy')}
							</Button>
						</Col>
						<Col>
							<Button icon={<PlusCircleOutlined />} href={t('paths:promoCodeCreate|path')} type={'primary'}>
								{t('Pridať promo kód')}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row gutter={[20, 12]} style={{ marginTop: '16px', marginBottom: '16px' }}>
				<Col>
					<Select
						style={{ width: 250 }}
						onChange={(value) => setFilter({ ...filter, state: value, page: 1 })}
						placeholder={t('Status')}
						value={filter.state}
						options={promoStates}
						filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					/>
				</Col>
				<Col>
					<Select
						style={{ width: 250 }}
						onChange={(value) => setFilter({ ...filter, type: value, page: 1 })}
						placeholder={t('Typ')}
						value={filter.type}
						options={promoTypes}
						filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					/>
				</Col>
				<Col>
					<Select
						style={{ width: 250 }}
						onChange={(value) => setFilter({ ...filter, lifespan: value, page: 1 })}
						placeholder={t('Platnosť')}
						value={filter.lifespan}
						options={promoLifespan}
						filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					/>
				</Col>
				<Col>
					<Button icon={<CloseCircleOutlined />} onClick={resetFilter}>
						{t('Zrušiť filter')}
					</Button>
				</Col>
			</Row>

			<Table
				className={'general-table'}
				columns={columns as unknown as ColumnsType<any>}
				dataSource={dataSource}
				onChange={handleTableChange}
				scroll={{ x: 'max-content' }}
				showSorterTooltip={false}
				pagination={{
					pageSize: PAGE_SIZE,
					total: get(context, 'totalCount'),
					current: get(context, 'page'),
					showSizeChanger: false
				}}
				loading={isLoadingList}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:promoCodeDetail|path')}/${get(record, 'key')}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
			<Modal
				open={generateModalOpen}
				title={t('Generovanie množiny promokódov')}
				cancelText={t('Zrušiť')}
				okText={t('Generovať')}
				onCancel={() => setGenerateModalOpen(false)}
				footer={[
					<Button key={'back'} onClick={() => setGenerateModalOpen(false)}>
						{t('Zrušiť')}
					</Button>,
					<Button
						key={'submit'}
						type={'primary'}
						form={'generate-promo-codes-form'}
						onClick={() => dispatch(submit(FORMS.GENERATE_PROMO_CODES))}
						disabled={pristine || invalid}
					>
						{t('Generovať')}
					</Button>
				]}
				maskClosable={false}
				destroyOnClose
				width={680}
			>
				<Spin spinning={isGenerating}>
					<GeneratePromoCodesForm formID={'generate-promo-codes-form'} onSubmit={handleGeneratePromoCodes} />
				</Spin>
			</Modal>
		</div>
	)
}

export default PromoCodes
