import dayjs from 'dayjs'
import i18next from 'i18next'
import isEmpty from 'lodash/isEmpty'
import { FormErrors } from 'redux-form'

// utils
import { PROMO_CODE_TARGET_DISCOUNT, PROMOCODE_LIFESPAN, PROMOCODE_STATE, PROMOCODE_TYPE } from '../../../utils/enums'

export type PromoCodeFormValues = {
	code: string
	forNewcomers: boolean
	state: PROMOCODE_STATE
	type: PROMOCODE_TYPE
	targetDiscount: PROMO_CODE_TARGET_DISCOUNT[]
	recurringPeriods?: number
	maxUsageOfPromoCode?: number | null
	lifespan?: PROMOCODE_LIFESPAN
	argument?: any
	validFrom: string
	validTo: string
	premiumTierAllowed?: boolean
	vipTierAllowed?: boolean
	sendTransactionalEmail?: boolean
}

export default (values: PromoCodeFormValues) => {
	const errors: FormErrors<PromoCodeFormValues> = {}

	if (!values.code) {
		errors.code = i18next.t('Toto pole je povinné')
	}

	if (values.code && values.code.length > 50) {
		errors.code = i18next.t('Maximálna dĺžka kódu je 50 znakov')
	}

	if (!values.type) {
		errors.type = i18next.t('Toto pole je povinné')
	}

	if (values.type === PROMOCODE_TYPE.DISCOUNT && isEmpty(values.targetDiscount)) {
		errors.targetDiscount = i18next.t('Toto pole je povinné')
	}

	if (values.type === PROMOCODE_TYPE.DISCOUNT && values.targetDiscount.includes(PROMO_CODE_TARGET_DISCOUNT.RECURRING) && !values?.recurringPeriods) {
		errors.recurringPeriods = i18next.t('Toto pole je povinné')
	}

	if (values.recurringPeriods && values.recurringPeriods < 1) {
		errors.recurringPeriods = i18next.t('Minimálna hodnota je 1')
	}

	if (!values.argument) {
		errors.argument = i18next.t('Toto pole je povinné')
	}

	if (values.argument) {
		if (values.type === PROMOCODE_TYPE.DISCOUNT && !(values.argument >= 0 && values.argument <= 100)) {
			errors.argument = i18next.t('Zadajte číslo v rozsahu 0 až 100')
		} else if (!(values.argument >= 0 && values.argument <= 100)) {
			errors.argument = i18next.t('Zadajte číslo v rozsahu 0 až 100')
		}
	}

	if (values.type === PROMOCODE_TYPE.DISCOUNT && values.argument?.length > 50) {
		errors.argument = i18next.t('Maximálna dĺžka kódu je 50 znakov')
	}

	if (!values.lifespan) {
		errors.lifespan = i18next.t('Toto pole je povinné')
	}

	if (values.maxUsageOfPromoCode && values.maxUsageOfPromoCode < 1) {
		errors.maxUsageOfPromoCode = i18next.t('Minimálna hodnota je 1')
	}

	if (!values.validFrom) {
		errors.validFrom = i18next.t('Toto pole je povinné')
	}

	if (!values.validTo) {
		errors.validTo = i18next.t('Toto pole je povinné')
	}

	if (values.validFrom && values.validTo) {
		if (dayjs(values.validFrom).isAfter(dayjs(values.validTo))) {
			errors.validTo = i18next.t('Dátum konca platnosti musí byť väčší než začiatok')
		}
	}

	return errors
}
